.booking-header {
  width: 100%;
  height: 40vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: end;
  padding-bottom: 20px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("/public/images/booking_header.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
}

.booking-header h1 {
  color: white;
  font-size: 25pt;
  font-weight: 700;
  text-transform: uppercase;
}

.booking-header p {
  text-align: justify;
  font-size: 14pt;
  letter-spacing: 1.5px;
  color: white;
  padding-bottom: 20px;
  font-weight: 600;
}

.booking-form-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  width: 97%;
  align-items: center;
}

.booking-map {
  border-radius: 12px;
  width: 60%;
  height: 500px;
}

.booking-form {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.booking-form-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  background-color: rgb(82, 82, 244);
  color: white;
  font-weight: 800;
  font-size: 16pt;
  letter-spacing: 1.5px;
  border-radius: 12px;
}

.booking-form-section,
.booking-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.booking-form-input {
  margin-top: 20px;
  width: 100%;
}

.cost-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20pt;
  font-weight: 800;
  letter-spacing: 2px;
  color: gray;
  margin-top: 20px;
}

.cost-description {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9pt;
  font-style: italic;
  color: gray;
}

.booking-form-action {
  margin-top: 30px;
  width: 100%;
}

.booking-form-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .booking-form-section {
    flex-direction: column;
  }
  .booking-form-container {
    flex-direction: column;
  }
  .booking-form {
    width: 100%;
  }

  .booking-map {
    width: 100%;
  }
  .booking-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
  }
}
