.servicecard-container {
  display: flex;
  justify-content: center;
  align-items: end;
  width: 250px;
  height: 250px;
  border-radius: 12px;
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
}

.servicecard-title {
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1.5px;
}
