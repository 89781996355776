.contacts-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.contacts-header {
  width: 100%;
  height: 40vh;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("/public/images/callcenter.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  padding-bottom: 20px;
}

.contacts-header h1 {
  font-size: 25pt;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
}

.contacts-header p {
  color: white;
  font-size: 14pt;
}

.contacts-body {
  display: flex;
  width: 90%;
  margin-top: 20px;
}

.contacts-form {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 50%;
}

.contacts-form h4,
.contacts-contacts h4 {
  font-size: 16pt;
  font-weight: 700;
  letter-spacing: 1.3px;
  margin-bottom: 10px;
}

.contacts-form form {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  height: auto;
}

.contacts-form form .form-control {
  width: 100%;
  margin-bottom: 10px;
}

.contacts-divider {
  width: 2px;
  height: auto;
  background: rgb(144, 134, 134);
  margin: 0 20px;
}

.contacts-contacts {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 50%;
}

.contacts-item {
  display: flex;
  justify-content: start;
  width: 100%;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
}

.contacts-item p {
  font-size: 14pt;
  color: rgb(181, 178, 178);
  font-weight: 700;
  letter-spacing: 1.5px;
  margin-left: 30px;
}
.address {
  align-items: baseline;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .contacts-body {
    flex-direction: column;
  }
  .contacts-form {
    width: 100%;
  }
}
