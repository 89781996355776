.home-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
}

.home-carousel {
  height: 100vh;
}

.home-carousel img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.carousel-legend {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: -100px;
  font-size: 25pt;
  letter-spacing: 2px;
}

.home-booknow {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(92, 92, 234);
}

.home-booknow .button {
  padding: 10px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: rgb(92, 92, 234);
  background: white;
  border-radius: 25px;
  font-weight: 700;
  letter-spacing: 1.5;
  text-transform: uppercase;
}

.home-booknow .button:hover {
  background: rgb(226, 222, 222);
  color: black;
  transition: 0.3s ease-in-out;
}

.home-company {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.home-services {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background: rgb(92, 92, 234);
}

.home-services h4 {
  color: white;
  font-size: 16pt;
  letter-spacing: 2px;
}

.home-services .service-list {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .home-company {
    flex-direction: column;
    justify-content: space-evenly;
  }
  .home-icon-card {
    margin-top: 40px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  }
  .service-list {
    flex-direction: column;
    align-items: center;
  }
  .service-card {
    margin-top: 20px;
  }
  .home-carousel {
    height: 70vh;
  }
  .home-carousel img {
    height: 70vh;
  }
}
