.icon-service-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
  border-radius: 12px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 20vw;
  height: 45vh;
  padding: 10px;
}

.icon-service-container .title {
  width: 100%;
  display: flex;
  justify-content: start;
  font-size: 18pt;
  font-weight: 700;
}

.icon-service-container .content {
  margin-top: 10px;
  font-size: 14pt;
  text-align: justify;
}

@media screen and (max-width: 768px) {
  .icon-service-container {
    width: 60vw;
  }
}
