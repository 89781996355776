.services-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.services-header {
  height: 50vh;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("/public/images/wheelchairservice.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
}

.services-header h1 {
  color: white;
  font-size: 25pt;
  font-weight: 700;
  text-transform: uppercase;
}

.services-header p {
  margin: 20px 0;
  color: white;
  font-size: 14pt;
  width: 80%;
  text-align: justify;
}

.services-container h4 {
  font-size: 18pt;
  font-weight: 600;
  margin-top: 20px;
}

.services-container p {
  font-size: 14pt;
  letter-spacing: 1.3;
}

.services-list {
  padding: 20px;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

@media screen and (max-width: 768px) {
  .services-header {
    height: 70vh;
  }
  .services-list {
    flex-direction: column;
    align-items: center;
  }
  .serviceCard {
    margin-top: 20px;
    width: 60vw;
  }
  .service-icon-card {
    margin-top: 40px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  }
}
