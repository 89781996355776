.about-header {
  width: 100%;
  height: 40vh;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("/public/images/cityskyline.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
}

.about-header p {
  text-align: justify;
  width: 70%;
  font-size: 14pt;
  letter-spacing: 1.5px;
  color: aliceblue;
  padding-bottom: 20px;
}

.about-header h1 {
  color: white;
  font-size: 25pt;
  font-weight: 700;
  text-transform: uppercase;
}

.about-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
}

.about-info-text {
  width: 45%;
  text-align: justify;
}
.about-info-img {
  width: 50%;
  height: 40vh;
  object-fit: cover;
}

.about-driver {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.about-driver div {
  width: 48%;
  text-align: justify;
  display: flex;
  flex-direction: column;
  margin-left: 10vh;
}

.about-driver div h4 {
  font-size: 15pt;
  font-weight: 800;
  letter-spacing: 2px;
}

.about-driver div ul {
  list-style: circle;
}

.about-driver-img {
  width: 50%;
  height: 40vh;
  object-fit: cover;
}

.about-cards {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("/public/images/cloudy.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .about-header {
    height: 60vh;
  }

  .about-info {
    flex-direction: column-reverse;
    width: 95%;
    align-items: center;
  }
  .about-info-text {
    width: 100%;
  }
  .about-info-img {
    width: 100%;
  }
  .about-driver {
    flex-direction: column;
    width: 95%;
  }
  .about-driver-img {
    width: 100%;
  }
  .about-driver div {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }
  .about-cards {
    flex-direction: column;
  }
  .about-card {
    margin-top: 20px;
    width: 60vw;
  }
}
