@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,400;0,600;0,900;1,400;1,600;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root,
.app,
.content {
  font-family: "Source Sans 3", sans-serif;
  font-size: 14pt;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
