.custom-nav {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 80px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
  z-index: 1000;
  position: fixed;
  top: 60;
  transition: background-color 0.3s ease-in-out;
  transition-delay: 0.2s;
  transition-property: background-color;
}

.custom-nav ul {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.custom-nav ul li {
  padding: 0 20px;
  position: relative;
}
.nav-link {
  text-decoration: none;
  font-weight: 600;
  font-size: 1.3rem;
  color: whitesmoke;
  transition: 0.3s ease-in-out;
}

.nav-link:hover,
.nav-link-active,
#mobile:hover {
  color: rgb(92, 92, 234);
}

.nav-link:hover::after,
.nav-link-active::after {
  content: "";
  width: 30%;
  height: 2px;
  background-color: rgb(92, 92, 234);
  position: absolute;
  bottom: -4px;
  left: 20px;
}

.nav-logo {
  height: 80px;
  width: auto;
}

#mobile {
  display: none;
  color: black;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

@media screen and (max-width: 768px) {
  .custom-nav {
    padding: 20px 40px;
  }
  .custom-nav ul {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: fixed;
    top: 90px;
    right: -300px;
    width: 300px;
    height: 100vh;
    background-color: ivory;
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
    padding: 40px 0 0 10px;
    transition: 0.3s ease-in-out;
  }
  #mobile {
    display: block;
    color: white;
  }
  .custom-nav ul li {
    margin-bottom: 25px;
  }

  .nav-link {
    color: black;
  }
  .nav-link:hover,
  .nav-link-active,
  #mobile:hover {
    color: rgb(92, 92, 234);
  }

  .navbar-active {
    right: 0px !important;
  }
}
