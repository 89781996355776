.footer-container {
  margin-top: 20px;
  padding-top: 30px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.sitemap {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.sitemap-header {
  font-size: 16pt;
  font-weight: 800;
}

.sitemap-link {
  text-decoration: none;
  font-weight: 600;
  font-size: 14pt;
  color: black;
}

.sitemap-link:hover {
  color: rgb(92, 92, 234);
}

.socials {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.footer-bottom {
  width: 100%;
  padding: 10px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(208, 208, 208);
}
