.card-container {
  display: flex;
  flex-direction: column;
  height: 40vh;
  width: 30vw;
  border-radius: 12px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.4);
}

.card-border {
  border: 2px solid black;
  border-radius: 12px;
  height: 39vh;
  width: 29vw;
  padding: 20px;
  background: rgba(255, 255, 255, 0.7);
}

.card-title {
  font-size: 16pt;
  font-weight: 700;
  color: black;
}

@media screen and (max-width: 768px) {
  .card-border {
    width: 59vw;
  }
}
